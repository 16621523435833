
.date-picker {
        border: none;
        outline: none;
        background-color: transparent;
        color: inherit;
        font: inherit;
        width: 100%;
        transition: border-color 0.3s;
    }
    
    
    /* .react-datepicker-popper {
        z-index: 1000000 !important;
    } */